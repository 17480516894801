import React, { useState } from "react"
import { Link } from "gatsby"

export default function Navbar(props) {
  const [open, setOpen] = useState(false)

  return (
    <nav className="bg-green-500">
      <div className="container mx-auto">
        <div className="relative flex items-center justify-between h-20">
          <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
            <button
              className="inline-flex items-center justify-center p-1 rounded-md text-gray-400 hover:text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white ml-4"
              aria-expanded="false"
            >
              <span
                className="sr-only"
                open={open}
                onClick={() => setOpen(!open)}
              >
                Open main menu
              </span>

              <svg
                className="block h-10 w-10"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
                aria-hidden="true"
                open={open}
                onClick={() => setOpen(!open)}
              >
                <path
                  d="M4 6h16M4 12h16M4 18h16"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </svg>
            </button>
          </div>
          <div className="flex-1 flex items-center justify-center lg:items-stretch lg:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/">
                <img
                  className="block h-14 sm:h-16 w-auto"
                  src={"/lf-logo-white.png"}
                  alt="Litigation Friend"
                />
              </Link>
            </div>
            <div className="hidden lg:ml-6 lg:flex flex-col justify-center">
              <div className="flex space-x-4">
                <Link
                  to="/"
                  activeClassName="bg-gray-900"
                  className="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm text-xl"
                >
                  Home
                </Link>
                <Link
                  to="/aboutus/"
                  activeClassName="bg-gray-900"
                  className="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm text-xl"
                >
                  About
                </Link>
                <Link
                  to="/templates/"
                  activeClassName="bg-gray-900"
                  className="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm  text-xl"
                >
                  Templates
                </Link>
                <Link
                  to="/freetools/"
                  activeClassName="bg-gray-900"
                  className="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm  text-xl"
                >
                  Free Tools
                </Link>
                <Link
                  to="/blog-index/"
                  activeClassName="bg-gray-900"
                  className="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm  text-xl"
                >
                  Blog
                </Link>
                <Link
                  to="/contact-us/"
                  activeClassName="bg-gray-900"
                  className="text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm  text-xl"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${open ? "block lg:hidden" : "hidden lg:hidden"} `}>
        <div className="px-2 pt-2 pb-3 space-y-1">
          <Link
            to="/"
            activeClassName="bg-gray-900"
            className="text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base"
          >
            Home
          </Link>
          <Link
            to="/aboutus/"
            activeClassName="bg-gray-900"
            className="text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base"
          >
            About
          </Link>
          <Link
            to="/templates/"
            activeClassName="bg-gray-900"
            className="text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base"
          >
            Templates
          </Link>
          <Link
            to="/freetools/"
            activeClassName="bg-gray-900"
            className="text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base"
          >
            Free Tools
          </Link>
          <Link
            to="/blog-index/"
            activeClassName="bg-gray-900"
            className="text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base"
          >
            Blog
          </Link>
          <Link
            to="/contact-us/"
            activeClassName="bg-gray-900"
            className="text-white hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </nav>
  )
}
